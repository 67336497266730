import React, { FC } from 'react';
import { Link } from 'gatsby';

import GatsbyImage from 'components/common/GatsbyImage';

import './HeaderLogo.scss';
import { IHeaderLogo } from './model';

const HeaderLogo: FC<IHeaderLogo> = ({ logo, link, logoAdditionalText }) =>
  logo?.url ? (
    <div className="gs-header-logo">
      <Link
        to={link.length ? link[0].url : '/'}
        aria-label={link.length ? link[0].name : 'Gaviscon'}
      >
        <GatsbyImage fluid={logo} alt={link.length ? link[0].name : 'Gaviscon'} />
        {logoAdditionalText ? <div>{logoAdditionalText}</div> : null}
      </Link>
    </div>
  ) : null;

export default HeaderLogo;
